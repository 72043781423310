<template>
  <div
    class="comp-wrpr"
    :class="{
      'details-hidden': hideDetails,
      'comp-wrpr-small': small,
    }"
  >
    <v-select-alt
      :rules="required ? [allRules.required] : []"
      :label="hideLabel ? null : $attrs.label || 'Role'"
      placeholder="Role"
      :value="value"
      @input="emitRole"
      :items="rolesFiltered"
      dense
      filled
      item-text="name"
      item-value="id"
      :loading="isRolesLoading"
      :readonly="readonly"
      :hide-details="hideDetails"
      :spanHorizontal="spanHorizontal"
      :clearable="clearable"
      style="flex: 1"
    >
      <template v-for="item in ['selection', 'item']" #[`${item}`]="data">
        <div
          :key="item + '_roleSelector'"
          class="d-flex align-center font-weight-medium"
          style="font-size: 14px"
        >
          {{ data.item.name }}
        </div>
      </template>
    </v-select-alt>
    <v-btn
      min-width="28px !important"
      width="28px !important"
      height="28px !important"
      color="secondary"
      outlined
      class="pa-0"
      small
      target="_blank"
      :to="`/roles/${value}`"
      :disabled="value == null || readonly"
      v-if="allowView"
    >
      <i class="fal fa-external-link"></i>
    </v-btn>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: [Number, String],
    },
    roles: {
      type: Array,
      default: () => [],
    },
    except: {
      type: Array,
      default: () => [],
    },
    obj: {
      type: Object,
    },
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    spanHorizontal: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    allowView: {
      type: Boolean,
      default: true,
    },
    isRolesLoading: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    rolesFiltered() {
      return this.roles.filter((r) => !this.except.some((e) => e == r.id));
    },
  },
  methods: {
    emitRole(id) {
      this.$emit("input", id);
      this.$emit("update:obj", id ? this.roles.find((elm) => elm.id == id) : null);
    },
  },
  watch: {
    obj() {},
  },
};
</script>
