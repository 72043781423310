<template>
  <!-- Create Role Modal -->
  <v-dialog v-model="modals.active" max-width="500px" persistent>
    <v-card>
      <v-card-title class="font-weight-bold d-flex" small>
        <i class="fal fa-clone mr-2"></i>Clone Role
      </v-card-title>
      <v-divider></v-divider>
      <v-form v-model="modals.valid" ref="cloneRoleForm">
        <v-card-text>
          <v-container class="py-0">
            <v-row class="my-0" dense>
              <v-col sm="12">
                <v-text-field-alt
                  :rules="[allRules.required, allRules.noWhiteSpaces]"
                  label="Role Name"
                  placeholder="Role Name"
                  v-model="modals.data.name"
                  :error-messages="modals.errorMessage"
                  :loading="modals.nameChecking"
                  hide-details="auto"
                  autofocus
                  @input="
                    modals.isNameValid = false;
                    modals.errorMessage = '';
                  "
                >
                </v-text-field-alt>
                <input type="text" disabled readonly style="display: none" />
                <span
                  v-if="!modals.isNameValid && modals.errorMessage == ''"
                  class="blue-grey--text"
                >
                  <i class="fas fa-circle-info mx-1"></i>Please Check Name
                </span>
                <span
                  v-else-if="modals.nameChecking && modals.errorMessage == ''"
                  class="indigo--text"
                >
                  <i class="fas fa-spinner-third fa-spin mx-1"></i>Checking
                  Name...
                </span>
                <span v-else-if="modals.errorMessage == ''" class="green--text">
                  <i class="fas fa-circle-check mx-1"></i>Valid Name
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cloneRoleDiscard()">
            <i class="fal fa-xmark mr-2"></i>Cancel
          </v-btn>
          <v-btn
            color="indigo"
            :dark="!modals.nameChecking && !modals.isNameValid"
            :disabled="modals.nameChecking || modals.isNameValid"
            @click="checkIfNameExist()"
          >
            <i
              class="fal fa-spinner-third fa-spin mr-2"
              v-if="modals.nameChecking"
            ></i>
            <i v-else class="fal fa-question mr-2"></i>
            <span v-if="modals.nameChecking">Checking Name...</span>
            <span v-else>Check Name</span>
          </v-btn>
          <v-btn
            color="info"
            :disabled="!modals.valid || modals.cloning || !modals.isNameValid"
            @click="cloneRoleConfirmed()"
          >
            <i
              class="fal fa-spinner-third fa-spin mr-2"
              v-if="modals.cloning"
            ></i>
            <i v-else class="fal fa-check mr-2"></i>
            <span v-if="modals.cloning">Cloning...</span>
            <span v-else>Clone</span>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import rolesService from "../services/roles-service";
import enums from "../../../plugins/enums";

export default {
  components: {},
  props: {},
  data() {
    return {
      modals: {
        active: false,
        valid: false,
        errorMessage: "",
        cloning: false,
        nameChecking: false,
        isNameValid: false,
        data: {
          name: null,
          roleId: null
        }
      }
    };
  },
  computed: {},
  methods: {
    stageChange() {
      this.modals.data.status = null;
    },
    open(role) {
      this.modals.active = true;
      this.modals.data.roleId = role.id;
      this.modals.data.name = this.roleNameConvention(role.name);
    },
    roleNameConvention(name) {
      return name + " [Clone]";
    },
    cloneRoleDiscard() {
      this.modals = {
        active: false,
        valid: false,
        errorMessage: "",
        cloning: false,
        nameChecking: false,
        data: {
          name: null,
          roleId: null
        }
      };
    },
    checkIfNameExist() {
      if (this.modals.data.name) {
        this.modals.nameChecking = true;
        rolesService
          .checkIfRoleNameExist(this.modals.data.name)
          .then(resp => {
            if (resp.data) {
              this.modals.isNameValid = false;
              this.modals.errorMessage = `"${this.modals.data.name}" Role name already exists!`;
            } else {
              this.modals.isNameValid = true;
              this.modals.errorMessage = "";
            }
          })
          .finally(() => {
            this.modals.nameChecking = false;
          });
      }
    },
    cloneRoleConfirmed() {
      this.modals.cloning = true;
      rolesService
        .cloneRole(this.modals.data)
        .then(resp => {
          this.$dialog.notify.success("Role cloned successfully!", {
            position: "top-right",
            timeout: 3000
          });
          this.cloneRoleDiscard();
          this.$emit("clone", resp.data);
        })
        .catch(err => {
          this.$handleError(err);
        })
        .finally(() => (this.modals.cloning = false));
    }
  }
};
</script>
